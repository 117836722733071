<template>
  <div class="py-8 mx-16 main">
    <v-row class="px-2">
      <v-col class="pa-0 grey lighten-4">
        <div class="text-h5 mb-4">TRAVERSA</div>
      </v-col>
    </v-row>
    <v-row class="mb-8 px-2">
      <v-col cols="12" class="pa-0 grey lighten-4">
        <label for="data-type-select" class="select-label">Choose data type you want to import</label>
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          class="select mb-8"
          v-model="selectedOption"
          :items="options"
          outlined
          id="data-type-select"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col class="pa-0">
        <v-card class="grey lighten-4">
          <v-card-text>
            <div>
              <label>Upload {{ selectedOption }} csv file</label>
            </div>
            <template v-if="!importing">
              <v-row>
                <v-col>
                  <v-file-input @change="upload" :value="fileValue" placeholder="Upload File"></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="primary" class="mx-2" @click="convertData">
                    {{ selectedOption == 'Trips' ? 'Validate' : 'Import' }}
                  </v-btn>
                  <v-btn
                    v-show="selectedOption == 'Trips'"
                    color="primary"
                    class="mx-2"
                    @click="importTrips"
                    :disabled="selectedOption == 'Trips' && !validated"
                  >
                    Import
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-progress-circular v-else :size="50" color="primary" indeterminate class="progress"></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="selectedOption == 'Trips' && validated">
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Trip ID</th>
                <th class="text-left">Missing Location/Destination</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in missing" :key="i">
                <td>{{ item.id }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import importApi from '@/apis/import';
import { mapGetters, mapActions } from 'vuex';
import { GET_DESTINATIONS } from '@/store/modules/Destination/actions';
import { GET_LOCATIONS } from '@/store/modules/Location/actions';
import { GET_DRIVERS } from '@/store/modules/Driver/actions';
import { GET_VEHICLES } from '@/store/modules/Vehicle/actions';
import { GET_STOPS } from '@/store/modules/Stop/actions';
import Papa from 'papaparse';

export default {
  name: 'TraversaImport',
  data() {
    return {
      fileValue: null,
      selectedOption: 'Trips',
      options: ['Trips', 'Locations', 'Destinations'],
      lines: [],
      type: {},
      data: [],
      missing: [],
      validated: false,
      importing: false,
    };
  },
  computed: {
    ...mapGetters('location', ['locations']),
    ...mapGetters('vehicle', ['vehicles']),
    ...mapGetters('destination', ['destinations']),
    locationNames() {
      return this.locations.map((e) => e.name.toLowerCase());
    },
    destinationNames() {
      return this.destinations.map((e) => e.name.toLowerCase());
    },
  },
  methods: {
    ...mapActions('destination', [GET_DESTINATIONS]),
    ...mapActions('driver', [GET_DRIVERS]),
    ...mapActions('location', [GET_LOCATIONS]),
    ...mapActions('vehicle', [GET_VEHICLES]),
    ...mapActions('stop', [GET_STOPS]),
    async upload(file) {
      if (!file) return;
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const csv = reader.result;
        Papa.parse(csv, {
          header: false,
          dynamicTyping: true,
          complete: (result) => {
            const data = result.data;
            this.lines = data;
            this.lines.shift();
          },
        });
      };
    },
    async convertData() {
      this.importing = true;
      this.data = [];
      if (this.selectedOption === 'Destinations' || this.selectedOption === 'Locations') {
        this.lines
          .filter((e) => e)
          .forEach((e) => {
            if (e[0]) {
              const dest = {
                name: e[0],
                address: {
                  address: e[1],
                  city: e[2],
                  state: e[3],
                  zip: e[4],
                },
                active: 1,
              };
              this.data.push(dest);
            }
          });
        try {
          await importApi.importFromTraversa({
            type: this.selectedOption.toLowerCase(),
            data: this.data,
          });
          this.$myalert.success(`${this.selectedOption} imported`);
          this.getDestinations();
          this.getLocations();
        } catch (e) {
          this.$myalert.error(`Failed to import: ${e.message}`);
        }
        this.importing = false;
      } else if (this.selectedOption == 'Trips') {
        this.lines
          .filter((e) => e)
          .forEach((e) => {
            if (!e[0]) return;
            const driver = e[15] ? e[15].split(':')[1].trim() : null;
            const tripRequest = {
              uniqueId: e[0],
              comments: e[1],
              leaveDate: this.formatDate(e[2]).date,
              leaveTime: this.formatDate(e[2]).time,
              returnDate: this.formatDate(e[3]).date,
              returnTime: this.formatDate(e[3]).time,
              requester: e[4],
              tripStatus: e[5],
              tripType: e[6],
              numStudents: parseInt(e[7], 10),
              numAdults: parseInt(e[8], 10),
              stopDescription: e[9],
              venueArriveTime: this.formatTime(e[10]),
              venueDepartTime: this.formatTime(e[11]),
              actualArriveTime: this.formatTime(e[12]),
              actualDepartTime: this.formatTime(e[13]),
              vehicleName: e[14],
              driverLastName: driver ? driver.split(',')[0] : null,
              driverFirstName: driver ? driver.split(',')[1].trim() : null,
            };
            if (tripRequest.uniqueId) this.data.push(tripRequest);
          });

        this.missing = this.data
          .filter(
            (e) =>
              e.stopDescription &&
              !this.locationNames.includes(e.stopDescription.toLowerCase()) &&
              !this.destinationNames.includes(e.stopDescription.toLowerCase())
          )
          .map((e) => ({ id: e.uniqueId, value: e.stopDescription }));

        this.validated = true;
        this.importing = false;
      }
    },
    formatDate(dateString) {
      const dateParts = dateString.split(' ')[0].split('/');
      if (dateParts.length === 3) {
        const [month, day, year] = dateParts;
        const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        return { date: formattedDate, time: dateString.split(' ')[1] };
      }
      return null; // Invalid date format
    },
    formatTime(time) {
      if (!time || !time.length) return '';
      const parts = time.split(' ');
      if (parts[1] == 'PM') {
        const timeSplit = parts[0].split(':');
        return `${Number(timeSplit[0]) + 12}:${timeSplit[1]}`;
      } else return parts[0];
    },
    async importTrips() {
      this.importing = true;
      try {
        const validated = this.data.filter((e) => !this.missing.map((m) => m.id).includes(e.uniqueId));
        await importApi.importFromTraversa({ type: this.selectedOption.toLowerCase(), data: validated });
        this.$myalert.success('Trips imported, please refresh page to reload data');
      } catch (e) {
        this.$myalert.error(`Failed to import: ${e.message}`);
      }
      this.importing = false;
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validatePhoneNumber(phone) {
      const phoneRegex = /^\d{7}$|^\d{10}$/;
      return phoneRegex.test(phone);
    },
  },
  watch: {
    selectedOption() {
      this.data = [];
      this.missing = [];
      this.validated = false;
    },
  },
};
</script>

<style scoped>
.select {
  width: 20% !important;
  padding: 2px !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 2px !important;
  height: 1rem !important;
}
.main {
  width: 80% !important;
  margin: auto !important;
}
.file-input-label {
  display: inline-block;
  background-color: rgb(96, 125, 139);
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.file-input {
  display: none;
}
.file-input-button {
  margin-right: 8px;
}
h4 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
  font-weight: 400;
}
span {
  padding: 2px !important;
}
label {
  font-size: 0.9rem !important;
}
.select-label {
  font-size: 0.9rem !important;
  color: #6e6e6e !important;
}
.tag {
  display: inline-block !important;
  line-height: 14px !important;
  margin-top: -2px !important;
  margin-bottom: -2px !important;
  margin-right: 3px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  background-color: #cac9c9 !important;
  border-radius: 3px !important;
  color: #000000 !important;
}
</style>
